import React, { useState, useEffect } from "react";
import dashboard from "../../assets/images/home/dashboardBg.webp";
//icons
import building from "../../assets/images/home/Group 9.webp";
import suitcase from "../../assets/images/home/Group 15.webp";
import usericon from "../../assets/images/home/Group 13.webp";
// import iconread from "../../assets/images/home/Group 27.webp";
import shakehand from "../../assets/images/home/Group 14.webp";
//bgs
import redbg from "../../assets/images/home/pngs-87.png";
import yellowbg from "../../assets/images/home/pngs-86.png";

import redbg1 from "../../assets/images/home/pngs-89.png";
import yellowbg1 from "../../assets/images/home/pngs-88.png";

import "./School.css";
import { Link, useNavigate } from "react-router-dom";
import Section5 from "../../assets/images/home/section5.webp";
import map from "../../assets/images/map.png";
// import "react-multi-carousel/lib/styles.css";
import { useSSR, useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { getStat } from "./../../Redux/Schools/SchoolAction";
import { useDispatch, useSelector } from "react-redux";
function School() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { stat } = useSelector((store) => store.SchoolReducer);
  useEffect(() => {
    dispatch(getStat());
  }, []);

  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  return (
    <div className="home_section_cont">
      <div className="home_career">
        <div className="home_career_text_cont">
          <div className="home_career_title">
            {lang == "en" && <p>Start your TVET education path</p>}
            {lang == "ar" && <p>ابدأ مسار التعليم والتدريب التقني والمهني</p>}
          </div>
        </div>
        <div className="home_career_img_container">
          <img src={dashboard} alt="dashboard" className="home_career_img" />
        </div>
      </div>
      <div className="home_stats">
        <div className="home_stats_cont">
          <div className="home_stats_item_icon">
            <img
              src={lang == "en" ? redbg : redbg1}
              alt="building"
              className="home_stats_icon"
            />
          </div>
          <div
            className={
              lang === "en" ? "home_stats_item_text" : "home_stats_item_textar"
            }
          >
            <div className="home_stats_item_number">
              {stat?.data?.data?.school}
            </div>
          </div>
        </div>
        <div className="home_stats_cont">
          <div className="home_stats_item_icon">
            <img
              src={lang == "en" ? yellowbg : yellowbg1}
              alt="building"
              className="home_stats_icon"
            />
          </div>
          <div
            className={
              lang === "en" ? "home_stats_item_text" : "home_stats_item_textar"
            }
          >
            <div className="home_stats_item_number">
              {stat?.data?.data?.major}
            </div>
          </div>
        </div>
      </div>
      <div className="home_section5_cont">
        <div className="home_section5_cont_all">
          <div className="home_section5_text_container">
            <div className="divofb">
              <div className="home_section5_title">
                {lang == "en" && <div>Did you know?</div>}
                {lang == "ar" && <div>هل تعلم ؟</div>}
              </div>
            </div>
            <div className="divofb">
              <div className="home_section5_text">
                {lang == "en" && (
                  <div>
                    {" "}
                    Our majors will give you a general idea about the major you
                    choose, its duration, the pre-requisites to join the major
                    and the acquired skills upon graduation.
                  </div>
                )}
                {lang == "ar" && (
                  <div>
                    تخصصاتنا ستمنحك فكرة عامة عن التخصص الذي تختاره، ومدة
                    الدراسة فيه، والمتطلبات الأساسية للانضمام إلى التخصص،
                    والمهارات التي ستكتسبها عند التخرج.
                  </div>
                )}
              </div>
            </div>
            <div className="divofb">
              <div className="home_section9_text">
                {lang == "en" && (
                  <div>Unlock Your Future: Explore TVET Majors Today! </div>
                )}

                {lang == "ar" && (
                  <div>أنت بحاجة إلى معرفة المزيد عن التخصصات ؟</div>
                )}
              </div>
            </div>
            <div className="divofb" onClick={() => navigate("/majordetails")}>
              <button className="buttonmajor">
                {lang == "en" && <div> Explore Majors </div>}

                {lang == "ar" && <div>استكشف الاختصاصات </div>}
              </button>
            </div>
          </div>
          <div className="home_section5_img_container">
            <img src={Section5} className="home_section5_img" />
          </div>
        </div>
      </div>
      <div className="home_section100_cont">
        <div className="home_section5_cont_all">
          <div className="home_section5_imgsecond_container">
            <img src={map} className="home_section5_img" />
          </div>
          <div className="secondone">
            <div className="home_section5_title">
              {lang == "en" && (
                <div>
                  {" "}
                  Ready to Find the Perfect School Nearby? Start Exploring Maps!
                </div>
              )}
              {lang == "ar" && (
                <div>
                  {" "}
                  هل انت مستعد للعثور على المدرسة المثالية بالقرب منك ؟ ابد في
                  استكشاف الخرائط ؟
                </div>
              )}
            </div>

            <div className="home_section5second_subtitle">
              {lang == "en" && (
                <div>
                  {" "}
                  Our interactive map will guide you to your nearest education
                  choice.
                </div>
              )}
              {lang == "ar" && (
                <div> خريطتنا التفاعلية ستوجهك إلى أقرب خيار تعليمي لك.</div>
              )}
            </div>
            <button
              className="buttonmajor"
              onClick={() => navigate("/schoolsmap")}
            >
              {lang == "en" && <div>Browse Schools</div>}
              {lang == "ar" && <div>تصفح المدارس</div>}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default School;
