import React, { useState, useEffect } from "react";
import "./MajorDetailsSingle.css";

import { getonaemajor } from "./../../Redux/Major/MajorAction";
import { AiOutlineFieldTime } from "react-icons/ai";
import { SlGraduation } from "react-icons/sl";
import MajorCard from "../Component/MajorCard/MajorCard";
import { useNavigate } from "react-router-dom";
//import
import { IoIosArrowUp } from "react-icons/io";
import { MdOutlineArrowBack } from "react-icons/md";

import { useDispatch, useSelector } from "react-redux";

import { useSSR, useTranslation } from "react-i18next";
import i18n from "../../i18n";

import { useLocation } from "react-router-dom";
import Loader from "../Loader/Loader";
import { Link } from "react-router-dom";
function MajorDetailsSingle() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { singlemajor, loading2, error2 } = useSelector(
    (store) => store.MajorReducer
  );
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top of the page
  }, []);

  const handleClick = (event) => {
    event.preventDefault();

    const targetId = event.currentTarget.getAttribute("href").substring(1);
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      const offsetTop = targetElement.offsetTop;
      const windowHeight = window.innerHeight;

      // Calculate the scroll position to center the element
      const scrollTo = offsetTop - windowHeight / 2;

      // Scroll to the calculated position
      window.scrollTo({
        top: scrollTo,
        behavior: "smooth",
      });
    }
  };

  const location = useLocation();
  var url = window.location.pathname;

  // Extract the ID from the URL path
  var id = url.split("/")[2];
  useEffect(() => {
    dispatch(getonaemajor(id));
    window.scrollTo(0, 0);
  }, [id]);

  const [Majors, setMajoes] = useState("");

  useEffect(() => {
    if (singlemajor) {
      setMajoes(singlemajor?.data);
    }
  }, [singlemajor]);
  //i want function to use it onclick make  window.scrollTo(0, 0);
  const handleScrollTop = () => {
    window.scrollTo(0, 0);
  };
  if (loading2) {
    return <Loader />;
  } else {
    return (
      <div className="container-first">
        <div className="back-major">
          <Link to="/majordetails">
            <MdOutlineArrowBack
              size={50}
              className={lang == "en" ? "back-major" : "back-majorar"}
            />{" "}
          </Link>
        </div>
        <div className="header-title">
          <div className={lang == "en" ? "title-name" : "title-name_ar"}>
            {lang == "en" && (
              <div>
                {Majors?.data?.level?.name_en} - {Majors?.data?.major?.name_en}
              </div>
            )}
            {lang == "ar" && (
              <div>
                {Majors?.data?.level?.name_ar} - {Majors?.data?.major?.name_ar}
              </div>
            )}
          </div>
        </div>
        <div className="second-containerss">
          <div className={lang == "en" ? "fisrtinsecond" : "fisrtinsecond_ar"}>
            <div className="title-content">Content</div>
            <hr />
            <div className="elemntinc">
              <a href="#target" onClick={handleClick}>
                {lang == "en" && <div> Description </div>}
              </a>
              {lang == "ar" && <div> المواصفات</div>}
            </div>

            <div className="elemntinc">
              <a href="#target1" onClick={handleClick}>
                {lang == "en" && <div> Pre-requisites </div>}
                {lang == "ar" && <div> المتطلبات الأساسية</div>}
              </a>
            </div>
            <div className="elemntinc">
              <a href="#target2" onClick={handleClick}>
                {lang == "en" && <div> Acquired Skills </div>}
                {lang == "ar" && <div> المهارات المكتسبة</div>}
              </a>
            </div>
            <div className="elemntinc">
              <a href="#target3" onClick={handleClick}>
                {lang == "en" && <div> Main Task to Apply at Work </div>}
                {lang == "ar" && <div> المهمات في العمل</div>}
              </a>
            </div>
            <div className="elemntinc">
              <a href="#target4" onClick={handleClick}>
                {lang == "en" && <div> Personality Task </div>}
                {lang == "ar" && <div> السمات الشخصية</div>}
              </a>
            </div>
            <div className="elemntinc">
              <a href="#target5" onClick={handleClick}>
                {lang == "en" && <div> Next Level </div>}
                {lang == "ar" && <div> المرحلة التالية</div>}
              </a>
            </div>
            <div className="elemntinc">
              <a href="#target6" onClick={handleClick}>
                {lang == "en" && <div> Example of future jobs </div>}
                {lang == "ar" && <div> أمثلة عن الوظائف المستقبلية</div>}
              </a>
            </div>
          </div>
          <div
            className={lang == "en" ? "secondinsecond" : "secondinsecond_ar"}
          >
            <div className="first--row">
              <div className="firstelemnt-row">
                <AiOutlineFieldTime size={40} className="icon" />
                {lang == "en" && <div>{Majors?.data?.years} years</div>}
                {lang == "ar" && <div>{Majors?.data?.years} سنين</div>}
              </div>
              <div className="firstelemnt-row">
                <SlGraduation size={45} className="icon" />
                {lang == "en" && <div>{Majors?.data?.type_en} </div>}
                {lang == "ar" && <div>{Majors?.data?.type_ar} </div>}
              </div>
            </div>

            <div className="paragraph-title" id="target">
              {lang == "en" && <div> Description </div>}
              {lang == "ar" && <div> المواصفات</div>}
            </div>

            <div className="paragraph-description">
              {lang == "en" && (
                <div>
                  {" "}
                  <div style={{ whiteSpace: "pre-line" }}>
                    {Majors?.data?.description_en &&
                      Majors.data.description_en
                        .split("-")
                        .map((item, index) => (
                          <React.Fragment key={index}>
                            - {item}
                            <br />
                          </React.Fragment>
                        ))}
                  </div>{" "}
                </div>
              )}
              {lang == "ar" && (
                <div>
                  {" "}
                  {Majors?.data?.description_ar &&
                    Majors.data.description_ar.split("-").map((item, index) => (
                      <React.Fragment key={index}>
                        - {item}
                        <br />
                      </React.Fragment>
                    ))}
                </div>
              )}
            </div>

            <div className="paragraph-title" id="target1">
              {lang == "en" && <div> Pre-requisites </div>}
              {lang == "ar" && <div> المتطلبات الأساسية</div>}
            </div>

            <div className="paragraph-description">
              {lang == "en" && (
                <div>
                  {" "}
                  <div style={{ whiteSpace: "pre-line" }}>
                    {Majors?.data?.pre_requisites_en &&
                      Majors.data.pre_requisites_en
                        .split("-")
                        .map((item, index) => (
                          <React.Fragment key={index}>
                            - {item}
                            <br />
                          </React.Fragment>
                        ))}
                  </div>{" "}
                </div>
              )}
              {lang == "ar" && (
                <div>
                  {" "}
                  {Majors?.data?.pre_requisites_ar &&
                    Majors.data.pre_requisites_ar
                      .split("-")
                      .map((item, index) => (
                        <React.Fragment key={index}>
                          - {item}
                          <br />
                        </React.Fragment>
                      ))}
                </div>
              )}
            </div>
            <div className="paragraph-title" id="target2">
              {lang == "en" && <div> Acquired Skills </div>}
              {lang == "ar" && <div> المهارات المكتسبة</div>}
            </div>

            <div className="paragraph-description">
              {lang == "en" && (
                <div>
                  {" "}
                  {Majors?.data?.acquired_skills_en &&
                    Majors?.data?.acquired_skills_en
                      .split("-")
                      .map((item, index) => (
                        <React.Fragment key={index}>
                          - {item}
                          <br />
                        </React.Fragment>
                      ))}
                </div>
              )}
              {lang == "ar" && (
                <div>
                  {" "}
                  {Majors?.data?.acquired_skills_ar &&
                    Majors?.data?.acquired_skills_ar
                      .split("-")
                      .map((item, index) => (
                        <React.Fragment key={index}>
                          - {item}
                          <br />
                        </React.Fragment>
                      ))}
                </div>
              )}
            </div>
            <div className="paragraph-title" id="target3">
              {lang == "en" && <div> Main Task to Apply at Work </div>}
              {lang == "ar" && <div> المهمات في العمل</div>}
            </div>

            <div className="paragraph-description">
              {lang == "en" && (
                <div>
                  {Majors?.data?.main_task_en &&
                    Majors?.data?.main_task_en.split("-").map((item, index) => (
                      <React.Fragment key={index}>
                        - {item}
                        <br />
                      </React.Fragment>
                    ))}
                </div>
              )}
              {lang == "ar" && (
                <div>
                  {" "}
                  {Majors?.data?.main_task_ar &&
                    Majors?.data?.main_task_ar.split("-").map((item, index) => (
                      <React.Fragment key={index}>
                        - {item}
                        <br />
                      </React.Fragment>
                    ))}
                </div>
              )}
            </div>
            <div className="paragraph-title" id="target4">
              {lang == "en" && <div> Personality Task </div>}
              {lang == "ar" && <div> السمات الشخصية</div>}
            </div>

            <div className="paragraph-description">
              {lang == "en" && (
                <div>
                  {" "}
                  {Majors?.data?.personality_traits_en &&
                    Majors?.data?.personality_traits_en
                      .split("-")
                      .map((item, index) => (
                        <React.Fragment key={index}>
                          - {item}
                          <br />
                        </React.Fragment>
                      ))}
                </div>
              )}
              {lang == "ar" && (
                <div>
                  {" "}
                  {Majors?.data?.personality_traits_ar &&
                    Majors?.data?.personality_traits_ar
                      .split("-")
                      .map((item, index) => (
                        <React.Fragment key={index}>
                          - {item}
                          <br />
                        </React.Fragment>
                      ))}
                </div>
              )}
            </div>
            <div className="paragraph-title" id="target5">
              {lang == "en" && <div> Next Level </div>}
              {lang == "ar" && <div> المرحلة التالية</div>}
            </div>
            <div className="major-show-next">
              {console.log(Majors?.Recommend)}
              {Majors?.Recommend?.map((category) => (
                <MajorCard
                  title={category?.new?.major?.name_en}
                  name={category?.new?.level?.id}
                  key={category.level_id}
                  id={category?.new_major_level_id}
                />
              ))}
            </div>

            <div className="paragraph-title" id="target6">
              {lang == "en" && <div> Example of future jobs </div>}
              {lang == "ar" && <div> أمثلة عن الوظائف المستقبلية</div>}
            </div>

            <div className="paragraph-description">
              {lang == "en" && (
                <div>
                  {Majors?.data?.example_en &&
                    Majors?.data?.example_en.split("-").map((item, index) => (
                      <React.Fragment key={index}>
                        - {item}
                        <br />
                      </React.Fragment>
                    ))}
                </div>
              )}
              {lang == "ar" && (
                <div>
                  {" "}
                  {Majors?.data?.example_ar &&
                    Majors?.data?.example_ar.split("-").map((item, index) => (
                      <React.Fragment key={index}>
                        - {item}
                        <br />
                      </React.Fragment>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={lang == "en" ? "fixed-button" : "fixed-buttonar"}>
          <button onClick={handleScrollTop} className="arrowww">
            <IoIosArrowUp size={40} color="white" />
          </button>
        </div>
      </div>
    );
  }
}

export default MajorDetailsSingle;
