import React, { useEffect, useState } from "react";
import "./Footer.css";
import { ImLocation } from "react-icons/im";
import { IoMail } from "react-icons/io5";
import logo from "../../assets/images/header/logo.png";
import facebook from "../../assets/images/footer/Group 55.png";
import instagram from "../../assets/images/footer/Group 56.png";
import linkedin from "../../assets/images/footer/Group 57.png";
import logofooter from "../../assets/images/footer/logo-footer.png";
import { HiPhone } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useSSR, useTranslation } from "react-i18next";
import i18n from "../../i18n";
function Footer() {
  const navigate = useNavigate();
  const emailAddress = "info@mehnati.org";
  const handleEmailClick = () => {
    window.location.href = `mailto:${emailAddress}`;
  };
  const phoneNumber = "+96181061035";

  const handlePhoneClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };
  const handlefb = () => {
    window.open("https://www.facebook.com/mehnatilb", "_blank");
  };
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);
  const googleMapsLink = "https://goo.gl/maps/Szs9mq39KEocPuCp6";
  return (
    <div className="Main_footer_div">
      <div className="footer_header">
        <div className="underline_foot" />
        <div className="img_cont">
          <img src={logofooter} alt="logo" className="logo_header" />
        </div>
        <div className="underline_foot" />
      </div>
      <div className="footer_container">
        <div className="logo_cont">
          <div className="logo_cont_img">
            <img src={logo} alt="logo" className="logo_header" />
          </div>

          {lang == "en" && (
            <div className="logo_cont_text">
              A social platform curated for the student community in Lebanon,to
              foster decision making in choosing their career
            </div>
          )}
          {lang == "ar" && (
            <div className="logo_cont_text">
              منصة تراففك في اختيار مسيرتك المهنية و تساعدك على تعزيز امكانية
              اتخاذ القرارات الصحيحة للدخول في سوق العمل
            </div>
          )}
        </div>
        <div className="footer_quick_links">
          {lang == "en" && (
            <div className="footer_quick_links_title">Quick Links</div>
          )}
          {lang == "ar" && (
            <div className="footer_quick_links_title">روابط </div>
          )}

          <div className="underline_footer" />
          <div className="footer_container_items">
            <div
              className="footer_quick_links_item"
              onClick={() => navigate("/login")}
            >
              {lang == "en" && <div className="logo_cont_text">Sign up</div>}
              {lang == "ar" && <div className="logo_cont_text">انشاء ملف</div>}
            </div>
            <div
              className="footer_quick_links_item"
              onClick={() => navigate("/cvbuilder")}
            >
              {lang == "en" && (
                <div className="logo_cont_text">Create your CV </div>
              )}
              {lang == "ar" && (
                <div className="logo_cont_text">انشاء سيرة ذاتية</div>
              )}
            </div>
            <div
              className="footer_quick_links_item"
              onClick={() => navigate("/contactus")}
            >
              {lang == "en" && <div className="logo_cont_text">Contact Us</div>}
              {lang == "ar" && <div className="logo_cont_text">تواصل معنا</div>}
            </div>
            <div
              className="footer_quick_links_item"
              onClick={() => navigate("/login")}
            >
              <div className="logo_cont_text">LMS</div>
            </div>
            <div
              className="footer_quick_links_item"
              onClick={() => navigate("/login")}
            >
              <div className="logo_cont_text">Job Portal</div>
            </div>

            {/* <div className="footer_quick_links_item">Media Content</div>
            <div className="footer_quick_links_item">Q&A</div> */}
          </div>
        </div>
        <div className="footer_quick_links">
          {lang == "en" && (
            <div className="footer_quick_links_title">Contact info</div>
          )}
          {lang == "ar" && (
            <div className="footer_quick_links_title">معلومات للتواصل</div>
          )}
          <div className="underline_footer" />
          <div className="footer_container_items">
            <div className="footer_quick_links_item1">
              <a
                href={googleMapsLink}
                target="_blank"
                rel="noopener noreferrer"
                className="footer_quick_links_item1 a"
              >
                <ImLocation size={25} color="#171546" />
                <span className="hadath"> Hadath,Lebanon</span>
              </a>
            </div>
            <div
              className="footer_quick_links_item1"
              onClick={handleEmailClick}
            >
              <IoMail size={25} /> info@mehnati.org
            </div>
            <div
              className="footer_quick_links_item1"
              onClick={handlePhoneClick}
            >
              <HiPhone size={25} /> +961 81 061 035
            </div>
            <div className="logo_cont_social">
              <a href="https://www.facebook.com/mehnatilb" target="_blank">
                <img
                  src={facebook}
                  alt="facebook"
                  className="logo_cont_social_img"
                />
              </a>
              <a
                href="https://instagram.com/mehnatilb?igshid=MzRlODBiNWFlZA=="
                target="_blank"
              >
                <img
                  src={instagram}
                  alt="instagram"
                  className="logo_cont_social_img"
                />
              </a>
              <a
                href=" https://www.linkedin.com/company/mehnatilb/"
                target="_blank"
              >
                <img
                  src={linkedin}
                  alt="linkedin"
                  className="logo_cont_social_img"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="footer_footer_emailsub">
        <div className="footer_emailsub_text">Subscribe To Our NewsLetter</div>
        <div className="footer_emailsub_input">
          <input
            type="text"
            placeholder="Enter your email"
            className="footer_input"
          />
          <button className="button_footer">Subscribe</button>
        </div>
      </div> */}
    </div>
  );
}

export default Footer;
