import { createSlice } from "@reduxjs/toolkit";

const initialState = {

  majorss: [],

  loading1: false,
  error1: "",

  singlemajor: [],

  loading2: false,
  error2: "",


};

const MajorSlice = createSlice({
  name: "major",
  initialState,
  reducers: {
    majorRequest: (state) => {
      state.loading1 = true;
    },
    majorSuccess: (state, action) => {
      state.loading1 = false;
      state.majorss = action.payload;
    },
    majorFail: (state, action) => {
      state.loading1 = false;
      state.error1 = action.payload;
    },
    onemajorRequest: (state) => {
      state.loading2 = true;
    },
    onemajorSuccess: (state, action) => {
      state.loading2 = false;
      state.singlemajor = action.payload;
    },
    onemajorFail: (state, action) => {
      state.loading2 = false;
      state.error2 = action.payload;
    },
 
  },
});

export const MajorAction = MajorSlice.actions;
export default MajorSlice;
