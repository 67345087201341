import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  level: [],
  majors: [],
  loading: false,
  error: null,
  schools: [],
  school: [],
  stat: [],
  gallery: [],
  loading1: false,
  error1: "",
  loading2: false,
  error2: "",
  loading3: false,
  error3: "",
  loading4: false,
  error4: "",
  districts: [],

  loading5: false,
  error5: "",
};

const SchoolsSlice = createSlice({
  name: "schools",
  initialState,
  reducers: {
    majorRequest: (state) => {
      state.loading1 = true;
    },
    majorSuccess: (state, action) => {
      state.loading1 = false;
      state.majors = action.payload;
    },
    majorFail: (state, action) => {
      state.loading1 = false;
      state.error1 = action.payload;
    },
    levelRequest: (state) => {
      state.loading2 = true;
    },
    levelSuccess: (state, action) => {
      state.loading2 = false;
      state.level = action.payload;
    },
    levelFail: (state, action) => {
      state.loading2 = false;
      state.error2 = action.payload;
    },
    schoolRequest: (state) => {
      state.loading3 = true;
    },
    schoolSuccess: (state, action) => {
      state.loading3 = false;
      state.schools = action.payload;
    },
    schoolFail: (state, action) => {
      state.loading3 = false;
      state.error3 = action.payload;
    },
    oneschoolRequest: (state) => {
      state.loading4 = true;
    },
    oneschoolSuccess: (state, action) => {
      state.loading4 = false;
      state.school = action.payload;
    },
    oneschoolFail: (state, action) => {
      state.loading4 = false;
      state.error4 = action.payload;
    },
    districtRequest: (state) => {
      state.loading5 = true;
    },
    districtSuccess: (state, action) => {
      state.loading5 = false;
      state.districts = action.payload;
    },
    districtFail: (state, action) => {
      state.loading5 = false;
      state.error5 = action.payload;
    },

    statRequest: (state) => {
      state.loading5 = true;
    },
    statSuccess: (state, action) => {
      state.loading5 = false;
      state.stat = action.payload;
    },
    statFail: (state, action) => {
      state.loading5 = false;
      state.error5 = action.payload;
    },

    getgalleryRequest: (state, action) => {
      state.loading = true;
      state.error = null;
      state.gallery = [];
    },
    getgallerySuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.gallery = action.payload;
    },
    getgalleryFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.gallery = [];
    },
  },
});

export const SchoolsAction = SchoolsSlice.actions;
export default SchoolsSlice;
