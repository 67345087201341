import React, { useState, useEffect, useRef } from "react";

import imagehead from "./../../assets/images/singlepage/headingc.png";

import { IoIosArrowForward } from "react-icons/io";
import { BsFillTrashFill } from "react-icons/bs";

import { GrSearch } from "react-icons/gr";
import MajorCard from "../Component/MajorCard/MajorCard";
import "./MajorDetails.css";
import { getLevel, getMajor } from "./../../Redux/Schools/SchoolAction";
import { getMajorsss } from "./../../Redux/Major/MajorAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSSR, useTranslation } from "react-i18next";
import i18n from "../../i18n";

import Loader from "./../Loader/Loader";
function MajorDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { majors, level, error1, error2 } = useSelector(
    (store) => store.SchoolReducer
  );
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);
  const { majorss, loading1 } = useSelector((store) => store.MajorReducer);
  useEffect(() => {
    dispatch(getLevel());

    dispatch(getMajor());
    window.scrollTo(0, 0);
  }, []);

  const [Majors, setMajoes] = useState("");

  const [leveldata, setLeveldata] = useState("");
  const [majordata, setMajordata] = useState("");
  useEffect(() => {
    if (level) {
      setLeveldata(level);
    }
  }, [level]);
  useEffect(() => {
    if (majors) {
      setMajordata(majors?.data);
    }
  }, [majors]);
  useEffect(() => {
    if (majorss) {
      setMajoes(majorss);
    }
  }, [majorss]);
  const [search, setSearch] = useState("");
  const [Categories, setCategories] = useState(false);
  const [jobtypes, setjobtypes] = useState(false);

  useState(null);

  const handleCategories = () => {
    setCategories(!Categories);
  };
  const handletype = () => {
    setjobtypes(!jobtypes);
  };

  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleCategorySelection = (categoryId) => {
    setSelectedCategory(categoryId === selectedCategory ? null : categoryId);
  };
  const [selectedtype, setSelectedtype] = useState(null);

  const handletypeSelection = (typeName) => {
    if (selectedtype === typeName) {
      setSelectedtype(""); // Clear the selected type name
    } else {
      setSelectedtype(typeName); // Set the selected type name
    }
  };
  useEffect(() => {
    dispatch(
      getMajorsss(
        selectedCategory,

        selectedtype,

        search
      )
    );
  }, [dispatch, selectedCategory, selectedtype, search]);
  const handlereset = () => {
    setSelectedCategory("");
    setSelectedtype("");
    setSearch("");
    setCategories(null);
    setjobtypes(null);
  };
  const droplistRef = useRef(null);

  // Use an effect to attach a click event listener when the component mounts
  useEffect(() => {
    function handleClickOutside(event) {
      if (droplistRef.current && !droplistRef.current.contains(event.target)) {
        setCategories(null);
        setjobtypes(null);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div className="Mappage-container">
      <div className="image-head-map1">
        <div className="Title-map1">
          {lang == "en" && <div> Know your major</div>}
          {lang == "ar" && <div>استكشف الاختصاص</div>}
        </div>
        <img src={imagehead} alt="imagehead" className="image-head-map" />
      </div>
      <div className="filter_bar">
        <div className="upperfilters_box">
          <div className="search_box">
            <label className="cont_search">
              <GrSearch size={30} className="search_icon" />
              <input
                type="text"
                className="input_complete_search"
                placeholder={
                  lang == "en" ? "Search for Keywords" : "ابحث عن الاختصاص"
                }
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
            </label>
          </div>
        </div>
        <div ref={droplistRef} className="filter_icons_container">
          <div className={!Categories ? "icons_drop" : "icons_drop1"}>
            <div className="icons_dropdown">
              <div className="title_drop_cont" onClick={handleCategories}>
                <div className="title_drop">
                  {lang == "en" && <div>Educational Level</div>}
                  {lang == "ar" && <div>المستوى التعليمي</div>}
                </div>
                <div className="arrow_cont">
                  <IoIosArrowForward
                    size={30}
                    className={!Categories ? "arrow_icon_rot" : "arrow_icon"}
                  />
                </div>
              </div>
              {Categories && (
                <div className="Header_nav_dropdown_job">
                  <div className="Header_nav_dropdown_cont">
                    {leveldata?.data?.data?.map((category) => (
                      <div key={category.id} className="subsection_text_jobs">
                        <input
                          type="checkbox"
                          value={category.id}
                          name="category_filter"
                          className="checkbox_filter"
                          id={`category_${category.id}`}
                          checked={selectedCategory === category.id}
                          onChange={() => {
                            handleCategorySelection(category.id);
                          }}
                        />
                        <label htmlFor={`category_${category.id}`}>
                          {lang == "en" && <div> {category?.name_en}</div>}
                          {lang == "ar" && <div>{category?.name_ar}</div>}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div ref={droplistRef} className="icons_dropdown">
              <div className="title_drop_cont" onClick={handletype}>
                <div className="title_drop">
                  {lang == "en" && <div>Major</div>}
                  {lang == "ar" && <div>الاختصاص</div>}
                </div>
                <div className="arrow_cont">
                  <IoIosArrowForward
                    size={30}
                    className={!jobtypes ? "arrow_icon_rot" : "arrow_icon"}
                  />
                </div>
              </div>
              {jobtypes && (
                <div className="Header_nav_dropdown_job">
                  <div className="Header_nav_dropdown_cont">
                    {/* <div className="subsection_text">Sucess Stories</div>
                      <div className="subsection_text">Media Content</div>
                      <div className="subsection_text">Q&A </div> */}
                    {/* {categorylist.map((item) => (
                        <div className="subsection_text_jobs" key={item.id}>
                          {" "}
                          <input
                            type="checkbox"
                            className="checkbox_filter"
                            value={categoriesfilter}
                            name="category_filter"
                            id={`category_${item.id}`}
                            onChange={(e) =>
                              setcategoriesfilter(e.target.value)
                            }
                          />
                          <label htmlFor={`category_${item.id}`}>
                            {item.name_en}{" "}
                          </label>
                        </div>
                      ))} */}
                    {majordata?.data.map((category) => (
                      <div key={category.id} className="subsection_text_jobs">
                        <input
                          type="checkbox"
                          value={category.id}
                          name="category_filter"
                          className="checkbox_filter"
                          id={`category_${category.id}`}
                          checked={selectedtype === category.id}
                          onChange={() => {
                            handletypeSelection(category.id);
                          }}
                        />
                        <label
                          className="lable-name"
                          htmlFor={`category_${category.id}`}
                        >
                          {lang == "en" && <div> {category.name_en}</div>}
                          {lang == "ar" && <div>{category?.name_ar}</div>}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="title_drop_cont" onClick={handlereset}>
              <div className="trash_cont">
                <BsFillTrashFill size={30} className="trash_icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="title-first">
   Majors
</div> */}

      {loading1 ? (
        <Loader />
      ) : (
        <div className="majors_LIST">
          {/* {!majordata?.data  &&  <div>No Major Available</div>  } */}
          {Majors?.data?.data?.map((category) => (
            <Link to={`/majordetailsingle/${category.id}`}>
              <MajorCard
                title={
                  lang == "en"
                    ? category?.major?.name_en
                    : category?.major?.name_ar
                }
                name={category?.level_id}
              />
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

export default MajorDetails;
