import React, { useLayoutEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import School from "./School/School";
import Headers from "./Header/Header";

import Footer from "./Footer/Footer";
import SinglePage from "./SinglePage/SinglePage";
import SchoolMap from "./SchoolsMap/SchooolMap";
import MajorDetails from "./MajorDetails/MajorDetails";
import MajorDetailsSingle from "./MajorDetailsSingle/MajorDetailsSingle";
import Tvet from "./Tvet/Tvet";
function PageSwitch() {
  const location = useLocation();
  const [key, setKey] = useState(location.pathname);

  useLayoutEffect(() => {
    setKey(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <Headers />
      <Routes>
        <Route path="/" element={<School />} />
        <Route path="/singlepag/:id" element={<SinglePage />} />
        <Route path="/schoolsmap" element={<SchoolMap />} />
        <Route path="/tvet" element={<Tvet />} />
        <Route path="/majordetails" element={<MajorDetails />} />
        <Route path="/majordetailsingle/:id" element={<MajorDetailsSingle />} />
      </Routes>
      <Footer />
    </>
  );
}
export default PageSwitch;
