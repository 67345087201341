import React from "react";
import "./Tvet.css";
import axios from "axios";
import YouTube from "react-youtube";
import { useState, useEffect } from "react";

import { useSSR, useTranslation } from "react-i18next";
import i18n from "../../i18n";

import VideoPlayer from "./../Component/MajorCard/VideoPlayer";
function Tvet() {
  const videoUrl = "https://www.youtube.com/watch?v=VEdDFqRblnY";
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);
  return (
    <div className="tvet-cont">
      <div className="tvet-title">
        {lang == "en" && (
          <div>Vocational Education: A way to the labor market</div>
        )}
        {lang == "ar" && <div>التعليم المهني: طريق إلى سوق العمل</div>}
      </div>
      <div className="video">
        <VideoPlayer videoId="VEdDFqRblnY" />
      </div>
    </div>
  );
}

export default Tvet;
